import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';

const Locations = () => (
  <Layout>
    <h1>Terms and Conditions</h1>

    <p>Use of our service shall be governed by English law.</p>

    <p>While we will aim to provide a reliable, and useful service, we provide no guarantee of it's suitability for
    any specific purpose.</p>

    <p>As a user of this service, you must act to avoid causing it harm, be that by creating excessive or automated
    traffic or usage, or by attempting to find or exploit any vulnerability in our systems.</p>
  </Layout>
);

export default Locations;
